<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    :min-height="50"
    has-modal-card>
    <header class="modal-card-head">
      <div class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span>Advance Payment Entry</span>
      </div>
    </header>
    <section class="modal-card-body">
      <payment-entry-advance-payment v-model="entity"
        :is-new-entity="isNewEntity"
        :show-amount="showAmount"
        :read-only-view="readOnlyView" />
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button v-show="entity.isNew"
        class="button is-success tooltip"
        @click="save()"
        data-tooltip="Save">
        <span class="icon">
          <i class="mdi mdi-content-save mdi-18px" />
        </span>
        <span>Save</span>
      </button>
      <button v-show="!entity.isNew"
        class="button is-primary tooltip"
        @click="print()"
        data-tooltip="Print">
        <span class="icon">
          <i class="mdi mdi-printer mdi-18px" />
        </span>
        <span>Print</span>
      </button>
      <button class="button tooltip"
        @click="close()"
        data-tooltip="Close">Close</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import _debounce from 'lodash.debounce'
import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import HttpStatus from '@/components/http-status'
import { mapGetters } from 'vuex'
import { Emailer } from '@/classes'
import { RemarkTypes } from '@/enums'
import { KeyValuePairModel } from '@/classes/viewmodels'
import { BaseModalEx } from '@/components/BulmaModal'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import StoreMixin from './storeMixin'
import PaymentEntryAdvancePayment from './PaymentEntryAdvancePayment'
import PaymentEntryValidation from './PaymentEntryValidation'
import PaymentEntryService from './PaymentEntryService'

export default {
  name: 'PaymentEntryAdvancePaymentModal',
  components: {
    BaseModalEx,
    PaymentEntryAdvancePayment
  },
  mixins: [StoreMixin, DateTimeFiltersMixin, PaymentEntryValidation],
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-file-export'
    },
    enableAttach: {
      type: Boolean,
      default: true
    },
    defaultEmail: {
      type: String,
      default: ''
    },
    showAmount: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isActive: false,
      entity: null,
      isNewEntity: false
    }
  },
  computed: {
    ...mapGetters(['licenceExpired']),
    validateError() {
      return this.$v.$error || this.$v.advancePaymentGroup.$error
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    entity: {
      handler: _debounce(function(newVal) {
        if (newVal) {
          this.$forceUpdate()
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      }, 500),
      deep: true
    }
  },
  async created() {
    await this.getEntity()
  },
  mounted() {
    this.isActive = this.active || false
  },
  beforeDestroy() {
    if (_isEmpty(this.returnRoute)) {
      this.setReadOnlyView(false)
    }
  },
  methods: {
    async getEntity(reset = false) {
      try {
        this.$showSpinner()
        if (reset) {
          this.clearSessionStorage()
          this.clearSnapshots(this.entity.creditorPaymentID)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.entity.creditorPaymentID)
        }

        this.entity = _cloneDeep(this.currentSnapshot)
        this.isNewEntity = this.entity.isNew
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    async save() {
      const title = 'Advance Payment'
      if (this.validateError) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      } else if (this.entity.paid === 0) {
        this.$notification.openNotificationWithType('danger', title, 'Amount cannot be 0')
        return false
      } else if (this.entity.payDate < this.entity.glCloseDate) {
        this.$notification.openNotificationWithType('danger', title, 'Payment date cannot be in a closed Accounting Year.')
        return false
      }
      try {
        this.$showSpinner('Saving...')
        let response
        if (this.entity.isNew) {
          response = await PaymentEntryService.postEntity(this.entity)
        }
        this.$hideSpinner()
        if (response && response.status === HttpStatus.CREATED) {
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, 'New Advance Payment added')
        }
        return true
      } catch (e) {
        this.$hideSpinner()
        if (e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError('Advance Payment', e)
        }
        return false
      }
    },
    close() {
      this.clearSnapshots(this.entity.creditorPaymentID)
      this.clearSessionStorage()
      this.$emit('update:active', false)
      this.$emit('close')
    },
    async print() {
      const params = {
        REPORTTITLE: 'CREDITOR ADVANCE PAYMENT',
        CompanyID: this.$userInfo.companyId,
        VendorId: this.entity.vendorID,
        InvoiceID: this.entity.creditorInvoiceID,
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      }
      // Create KeyValuePairs and add to vuex for later retrieval by PrintPreviewEx component
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const reportName = this.entity.invoiceNoRef

      const emailer = new Emailer()
      emailer.assetId = this.entity.vendorID
      emailer.remark = 'Advance Payment ' + this.entity.invoiceNoRef
      emailer.remarkTypeId = RemarkTypes.Remark
      emailer.subject = 'Advance Payment - ' + this.entity.invoiceNoRef
      emailer.reportName = reportName
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'AT_CreditorInvoicePayment' },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|paymententry|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|paymententry|type`)
    },
    clearSessionStorage() {
      this.removeQueries()
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
