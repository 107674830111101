<template>
  <div>
    <article class="tile is-child box detail-page-tile">
      <div class="field is-horizontal">
        <div class="field-label is-normal label-width">
          <label class="label has-text-left">Vendor</label>
        </div>
        <div class="input-width">
          <div class="field">
            <multiselect v-model="selectedVendor"
              :class="{ 'is-danger' : $v.entity.vendorID.$error }"
              deselect-label="Can't remove this value"
              track-by="key"
              label="value"
              placeholder="Select Vendor"
              :options="vendors"
              :loading="isVendorLoading"
              :internal-search="false"
              :options-limit="vendorFilter.pageSize"
              :searchable="true"
              :show-labels="false"
              :allow-empty="true"
              :disabled="!isNewEntity"
              @select="selectVendor"
              @remove="removeVendor"
              @search-change="getVendorDropdownFilter">
              <template slot="option"
                slot-scope="props">
                <span>{{ props.option.value }}</span>
              </template>
              <span class="has-text-danger"
                slot="noResult">Vendor not found.</span>
            </multiselect>
            <span class="help is-danger"
              v-if="$v.entity && !$v.entity.vendorID.validGuid">Vendor is required.</span>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal label-width">
          <label class="label has-text-left">Pay Date</label>
        </div>
        <div class="input-width">
          <div class="field-body">
            <div class="field is-narrow">
              <div class="control">
                <div v-if="!isNewEntity"
                  class="control">
                  <input class="input is-static"
                    :value="$filters.formatDateTimezone(innerValue.payDate, $userInfo.locale)"
                    readonly>
                </div>
                <v-date-picker v-else
                  v-model="selectedPayDate"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  @input="selectedPayDate = $filters.fromISOWithCurrentTime($event.toISOString())"
                  :timezone="$filters.getIanaTimezone()">
                  <template v-slot="{ inputValue, inputEvents }"
                    :timezone="$filters.getIanaTimezone()">
                    <div class="field">
                      <div class="control has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Pay Date"
                          readonly
                          :value="inputValue"
                          v-on="inputEvents">
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="showAmount"
        class="field is-horizontal">
        <div class="field-label is-normal label-width"><label class="label has-text-left">Amount</label></div>
        <div class="input-width">
          <div class="field-body">
            <div class="field is-narrow">
              <div class="control">
                <vue-numeric class="input no-border has-text-right has-text-success"
                  v-model="innerValue.paid"
                  type="text"
                  symbol="$"
                  placeholder=""
                  :disabled="!isNewEntity"
                  :min="0"
                  :precision="2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="showAmount"
        class="field is-horizontal">
        <div class="field-label is-normal label-width">
          <label class="label has-text-left">Previous Advance</label>
        </div>
        <div class="input-width">
          <div class="field-body">
            <div class="field is-narrow">
              <div class="control">
                <vue-numeric class="input no-border has-text-right has-text-success"
                  v-model="innerValue.previousAdvance"
                  type="text"
                  symbol="$"
                  placeholder=""
                  :minus="true"
                  :disabled="true"
                  :precision="2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal label-input"><label class="label has-text-left">Total</label></div>
        <div class="input-width">
          <div class="field-body">
            <div class="field is-narrow">
              <div class="control">
                <vue-numeric class="input no-border has-text-right has-text-success"
                  type="text"
                  symbol="$"
                  placeholder=""
                  :value="total"
                  :minus="true"
                  :disabled="true"
                  :precision="2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal label-input">
          <label class="label has-text-left">Cheque No.</label>
        </div>
        <div class="input-width">
          <div class="field-body">
            <div class="field is-narrow">
              <p class="control">
                <input class="input is-capitalized"
                  v-model="innerValue.cheque"
                  type="text"
                  placeholder="Cheque No."
                  :disabled="!isNewEntity">
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal label-width"><label class="label has-text-left">Bank Account</label></div>
        <div class="input-width">
          <div class="field acc-column-width">
            <multiselect v-if="glBankAccountListCombo"
              v-model="selectedBankAccount"
              :options="glBankAccountListCombo"
              :loading="isGlBankAccountLoading"
              :disabled="!isNewEntity"
              open-direction="above"
              placeholder="Select account"
              label="displayName"
              deselect-label="Can't remove this value"
              track-by="accountNo"
              :allow-empty="false">
              <span class="has-text-danger"
                slot="noResult">Bank account not found.</span>
            </multiselect>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import _debounce from 'lodash.debounce'
import _cloneDeep from 'lodash/cloneDeep'
import Multiselect from 'vue-multiselect'
import Guid from '@/components/Guid'
import VueNumeric from '@/components/VueNumeric'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import VendorService from '@/views/vendor/VendorService'
import StoreMixin from './storeMixin'
import PaymentEntryService from './PaymentEntryService'

export default {
  name: 'PaymentEntryAdvancePayment',
  inject: ['$vv'],
  components: {
    Multiselect,
    VueNumeric
  },
  mixins: [StoreMixin, DateTimeFiltersMixin],
  props: {
    value: null,
    isNewEntity: {
      type: Boolean
    },
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-file-export'
    },
    enableAttach: {
      type: Boolean,
      default: true
    },
    defaultEmail: {
      type: String,
      default: ''
    },
    showAmount: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      isActive: false,
      vendors: [],
      selectedVendor: '',
      selectedPayDate: null,
      selectedBankAccount: null,
      isVendorLoading: false,
      isGlBankAccountLoading: false,
      glBankAccountListCombo: null,
      vendorFilter: {
        name: '',
        pageIndex: 1,
        pageSize: 50
      },
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    total() {
      if (this.showAmount) {
        return this.innerValue.paid + this.innerValue.previousAdvance
      } else {
        return this.innerValue.previousAdvance
      }
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    selectedPayDate: function(newVal, oldVal) {
      if (newVal) {
        this.innerValue.payDate = this.selectedPayDate.toISOString()
      } else {
        this.innerValue.payDate = ''
      }
    },
    selectedBankAccount: function(newVal, oldVal) {
      if (newVal) {
        this.innerValue.bankAccount = this.selectedBankAccount.accountNo
      } else {
        this.innerValue.bankAccount = ''
      }
    }
  },
  created() {
    this.selectedPayDate = new Date(this.innerValue.payDate)
    this.getVendorDropdown()
    this.GetGlBankAccountsDropdown()
  },
  mounted() {
    this.$v.advancePaymentGroup.$touch()
    this.isActive = this.active || false
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async getVendorDropdown() {
      this.isVendorLoading = true
      this.vendors = await VendorService.getVendorDropdownProto(this.vendorFilter)
      this.selectedVendor = this.vendors.find(i => i.key === this.innerValue.vendorID.toUpperCase())
      this.isVendorLoading = false
    },
    getVendorDropdownFilter: _debounce(async function(query) {
      this.isVendorLoading = true
      this.vendorFilter.name = query
      this.vendors = await VendorService.getVendorDropdownProto(this.vendorFilter)
      this.isVendorLoading = false
    }, 500),
    async selectVendor(selected) {
      this.vendorFilter.name = selected.Value
      this.innerValue.vendorID = selected.key
      this.innerValue.previousAdvance = await PaymentEntryService.GetTotalAdvancePaymentByVendor(selected.key)
    },
    removeVendor() {
      this.vendorFilter.name = ''
      this.selectedVendor = ''
      this.innerValue.vendorID = Guid.empty()
      this.getVendorDropdown()
    },
    resetSelectedVendors() {
      this.selectedVendor = ''
    },
    async GetGlBankAccountsDropdown() {
      this.isGlBankAccountLoading = true
      this.glBankAccountListCombo = await PaymentEntryService.getGlBankAccountsDropdown(this.$userInfo.companyId)
      this.selectedBankAccount = this.glBankAccountListCombo.find(g => g.accountNo === this.innerValue.bankAccount)
      this.isGlBankAccountLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
.label-width {
  float: left;
  width: 25%;
}
.input-width {
  float: left;
  width: 75%;
}
</style>
